var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "px-2 lg:px-6" },
    [
      _c("div", { staticClass: "p-4" }, [
        _c("h1", { staticClass: "mt-4 mb-8 text-3xl" }, [
          _vm._v("Board Application"),
        ]),
        _c(
          "div",
          { staticClass: "flex items-end" },
          [
            _c("board-selector", {
              attrs: { boards: _vm.boards },
              model: {
                value: _vm.application.board.id,
                callback: function ($$v) {
                  _vm.$set(_vm.application.board, "id", $$v)
                },
                expression: "application.board.id",
              },
            }),
            _c(
              "button-component",
              {
                staticClass: "ml-8 h-9",
                attrs: {
                  bg: "val-button-blue",
                  textOrBorderColor: "white",
                  disabled: !_vm.application.board.id,
                },
                on: { click: _vm.create },
              },
              [_vm._v("Apply to a Selection Board")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "my-4 ml-1 text-red-500 text-xxs" }, [
          _vm._v(
            " Application deadline is the last day of the month prior to the month in which the board convenes. "
          ),
        ]),
      ]),
      _c("caution-modal-component", {
        ref: "cautionModalComponent",
        attrs: {
          icon: ["fas", "triangle-exclamation"],
          title: "Warning",
          iconColor: "red",
          continueText: "Delete",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }