import { Application } from '@/models/Entities/Application/Application';
import { getTime } from '@/util/date';

// if the getter has something to do with applications, goes in here
export default class ApplicationService {
  /**
   * returns the latest application based on creation date
   *
   * @readonly
   * @type {Application}
   * @memberof ApplicationService
   */
  public static getLatestApplication(applications: Application[]): Application {
    return (
      [...applications].sort(
        (a, b) => b.createDateTime.getTime() - a.createDateTime.getTime()
      )[0] || null
    );
  }

  /**
   * finds the first application that has been marked as accepted
   * or has a uftBoardDateSelected value
   *
   * @readonly
   * @type {(Application | null)}
   * @memberof BaseCandidate
   */
  public static getAcceptedApplication(
    applications: Application[]
  ): Application | null {
    return (
      applications.find(
        (application) =>
          application.isAccepted || application.uftBoardDateSelected
      ) ?? null
    );
  }

  // /**
  //  * number of days since the student was accepted by
  //  * a UFT board
  //  *
  //  * @readonly
  //  * @memberof BaseCandidate
  //  */
  // public static getDaysSinceAcceptedByUftBoard(
  //   acceptedApplication: Application
  // ): number | null {
  //   const { uftBoardDateSelected } = acceptedApplication;
  //   return uftBoardDateSelected !== null
  //     ? dateDifference(acceptedApplication.uftBoardDateSelected, new Date())
  //     : null;
  // }

  // /**
  //  * calculates the students priority for the gains workflow
  //  * returns default LOW
  //  *
  //  * @readonly
  //  * @type {number}
  //  * @memberof BaseCandidate
  //  */
  // public static getPriority(
  //   latestApplication: Application | undefined
  // ): EGainsPriority {
  //   const priority = latestApplication?.candidate.gainsGainspriority;
  //   return priority ?? EGainsPriority.LOW;
  // }

  public static doesApplicationsExist(applications: Application[]): boolean {
    return applications && applications.length > 0;
  }

  public static getLatestApplicationByDate(
    applications: Application[]
  ): Application {
    const compareFn = (a: Application, b: Application) => {
      return getTime(a.createDateTime) - getTime(b.createDateTime);
    };
    const sorted = [...applications].sort(compareFn);
    return sorted[sorted.length - 1];
  }
}
