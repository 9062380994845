
import BoardSelector from '@/components/Board/BoardSelectorComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CautionModalComponent from '@/components/Modals/CautionModalComponent.vue';
import { SectionStatusData, SectionStatusType } from '@/components/types';
import { EIconColors } from '@/enums';
import { ESectionStatus } from '@/enums/ESectionStatus';
import { Application } from '@/models/Entities/Application/Application';
import { Board } from '@/models/Entities/Board';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import Routes from '@/router/Routes';
import ApplicationService from '@/services/application.service';
import { formatDateTime } from '@/services/formatService';
import { archiveApplicationCallback } from '@/util';
import { sortDatesDescending } from '@/util/date';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component<NewApplicationFormPageV2>({
  components: {
    BoardSelector,
    ButtonComponent,
    CautionModalComponent
  }
})
export default class NewApplicationFormPageV2 extends Vue {
  @Prop()
  applicant!: Applicant;

  @Prop()
  isUpdatingApplication!: boolean;

  @Prop({
    default: () => []
  })
  boards!: Board[];

  pastApplicationHeader = 'Application History';

  async mounted(): Promise<void> {
    const applications = await this.getAllApplications(this.applicant);
    const latestApplication =
      ApplicationService.getLatestApplication(applications);
    const id = this.applicant.id;

    const sectionsStatusData: SectionStatusData[] = [];

    if (latestApplication) {
      const header = 'Current Application';
      const filteredApplications = applications
        .filter(
          (application: Application) => application.id !== latestApplication.id
        )
        .sort((applicationOne, applicationTwo) =>
          sortDatesDescending(
            applicationOne.createDateTime,
            applicationTwo.createDateTime
          )
        );
      let pastApplicationEntries: SectionStatusType[] =
        this.filteredApplicationSections(filteredApplications);
      sectionsStatusData.push(
        {
          header: header,
          entries: [
            {
              text: formatDateTime(latestApplication.createDateTime),
              status: latestApplication.isComplete
                ? ESectionStatus.COMPLETE
                : ESectionStatus.PARTIAL,
              onClick: () => this.goToDetails(id, latestApplication.id),
              extraAction: {
                icon: ['far', 'trash-can'],
                iconColor: EIconColors.RED,
                onClick: async () => {
                  await archiveApplicationCallback(
                    this.applicant,
                    latestApplication,
                    0,
                    header,
                    this.$refs.cautionModalComponent
                  );
                }
              }
            }
          ]
        },
        {
          header: this.pastApplicationHeader,
          entries: pastApplicationEntries
        }
      );
    } else {
      sectionsStatusData.push(
        {
          header: 'Latest Application',
          entries: [
            {
              text: 'Start New Application',
              status: ESectionStatus.UNTOUCHED
            }
          ]
        },
        {
          header: this.pastApplicationHeader,
          entries: [
            {
              text: 'No Prior Application',
              status: ESectionStatus.UNTOUCHED
            }
          ]
        }
      );
    }
    this.$store.dispatch(
      'profileSideNavigationModule/setSections',
      sectionsStatusData
    );
  }

  @Watch('$route.params')
  fetchData(): void {
    this.$store.dispatch('profileSideNavigationModule/clear');
    this.$store.dispatch('boardModule/fetchAll');
    this.$store.dispatch('boardModule/fetchCurrent');
  }

  $refs!: {
    cautionModalComponent: CautionModalComponent;
  };

  filteredApplicationSections(
    filteredApplications: Application[]
  ): SectionStatusType[] {
    return filteredApplications.map((data: Application, index) => {
      return {
        text: formatDateTime(data.createDateTime),
        status: data.isComplete
          ? ESectionStatus.COMPLETE
          : ESectionStatus.PARTIAL,
        onClick: () => this.goToDetails(this.applicant.id, data.id),
        extraAction: {
          icon: ['far', 'trash-can'],
          iconColor: EIconColors.RED,
          onClick: async () => {
            await archiveApplicationCallback(
              this.applicant,
              data,
              index,
              this.pastApplicationHeader,
              this.$refs.cautionModalComponent
            );
          }
        }
      };
    });
  }

  application: Application = new Application();

  goToDetails(id: number, applicationId: number): void {
    this.$router.push({
      name: Routes.V2_APPLICANT_APPLICATION_DETAILS,
      params: { id: String(id), applicationId: String(applicationId) }
    });
  }

  async create(): Promise<void> {
    const id = String(this.applicant.id);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      ...this.application,
      candidateId: this.applicant.id
    };
    const result = await this.$store.dispatch(
      'applicantModule/createApplication',
      payload
    );
    this.$router.push({
      name: Routes.V2_APPLICANT_APPLICATION_DETAILS,
      params: { id, applicationId: String(result.id) }
    });
  }

  async getAllApplications(applicant: Applicant): Promise<Application[]> {
    return await this.$store.dispatch('applicantModule/findApplications', {
      archived: false,
      candidateIds: [applicant.id]
    });
  }
}
